import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
  dsn: "https://85a2460d54d24846963a314a247a790a@o1090356.ingest.sentry.io/4504576077004800",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1e-3,
  ignoreErrors: [
    "top.GLOBALS",
    // Random plugins/extensions
    "VK is",
    "VK.Retargeting is ",
    "Can't find variable: VK",
    "pktAnnotationHighlighter",
    "Unexpected keyword",
    "illegal character",
    "Unexpected identifier",
    "Illegal invocation",
    "missing = in const declaration"
  ],
  allowUrls: ["cv.hexlet.io"],
  beforeSend(event, hint) {
    var _a;
    const stack = ((_a = hint == null ? void 0 : hint.originalException) == null ? void 0 : _a.stack) || "";
    const errorInitiator = stack.split("\n").map((line) => line.trim()).find((line) => line.startsWith("at"));
    const causedByConsole = errorInitiator ? errorInitiator.includes("<anonymous>:") : false;
    return causedByConsole ? null : event;
  }
});
